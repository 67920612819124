<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Salut,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ user.nom }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="picture" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          S
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          Profil utilisateur
          <!--          <small class="text-muted font-size-sm ml-2">12 messages</small>-->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ user.nom }} {{ user.prenoms }}
            </a>
            <div class="text-muted mt-1">{{ user.roles[0]["slug"] }}</div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ user.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Déconnexion
            </button>
          </div>
        </div>
        <!--end::Header-->
        <!--        <div class="separator separator-dashed mt-8 mb-5"></div>-->
        <!--        &lt;!&ndash;begin::Nav&ndash;&gt;-->
        <!--        <div class="navi navi-spacer-x-0 p-0">-->
        <!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
        <!--          <router-link-->
        <!--            to="/builder"-->
        <!--            @click.native="closeOffcanvas"-->
        <!--            href="#"-->
        <!--            class="navi-item"-->
        <!--          >-->
        <!--            <div class="navi-link">-->
        <!--              <div class="symbol symbol-40 bg-light mr-3">-->
        <!--                <div class="symbol-label">-->
        <!--                  <span class="svg-icon svg-icon-md svg-icon-success">-->
        <!--                    &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
        <!--                    <inline-svg-->
        <!--                      src="media/svg/icons/General/Notification2.svg"-->
        <!--                    />-->
        <!--                    &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--                  </span>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="navi-text">-->
        <!--                <div class="font-weight-bold">Mon profil</div>-->
        <!--                <div class="text-muted">-->
        <!--                  Informations du compte et autres-->
        <!--                  <span-->
        <!--                    class="label label-light-danger label-inline font-weight-bold"-->
        <!--                  >-->
        <!--                    Mise à jour-->
        <!--                  </span>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </router-link>-->
        <!--          &lt;!&ndash;end:Item&ndash;&gt;-->
        <!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
        <!--          <router-link-->
        <!--            to="/builder"-->
        <!--            @click.native="closeOffcanvas"-->
        <!--            href="#"-->
        <!--            class="navi-item"-->
        <!--          >-->
        <!--            <div class="navi-link">-->
        <!--              <div class="symbol symbol-40 bg-light mr-3">-->
        <!--                <div class="symbol-label">-->
        <!--                  <span class="svg-icon svg-icon-md svg-icon-warning">-->
        <!--                    &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
        <!--                    <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" />-->
        <!--                    &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--                  </span>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="navi-text">-->
        <!--                <div class="font-weight-bold">Mes messages</div>-->
        <!--                <div class="text-muted">Boite de recetion et tâches</div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </router-link>-->
        <!--          &lt;!&ndash;end:Item&ndash;&gt;-->
        <!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
        <!--          <router-link-->
        <!--            to="/builder"-->
        <!--            @click.native="closeOffcanvas"-->
        <!--            href="#"-->
        <!--            class="navi-item"-->
        <!--          >-->
        <!--            <div class="navi-link">-->
        <!--              <div class="symbol symbol-40 bg-light mr-3">-->
        <!--                <div class="symbol-label">-->
        <!--                  <span class="svg-icon svg-icon-md svg-icon-danger">-->
        <!--                    &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
        <!--                    <inline-svg src="media/svg/icons/Files/Selected-file.svg" />-->
        <!--                    &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--                  </span>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="navi-text">-->
        <!--                <div class="font-weight-bold">Mes activités</div>-->
        <!--                <div class="text-muted">Notifications</div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </router-link>-->
        <!--          &lt;!&ndash;end:Item&ndash;&gt;-->
        <!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
        <!--          <router-link-->
        <!--            to="/builder"-->
        <!--            @click.native="closeOffcanvas"-->
        <!--            href="#"-->
        <!--            class="navi-item"-->
        <!--          >-->
        <!--            <div class="navi-link">-->
        <!--              <div class="symbol symbol-40 bg-light mr-3">-->
        <!--                <div class="symbol-label">-->
        <!--                  <span class="svg-icon svg-icon-md svg-icon-primary">-->
        <!--                    &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
        <!--                    <inline-svg-->
        <!--                      src="media/svg/icons/Communication/Mail-opened.svg"-->
        <!--                    />-->
        <!--                    &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--                  </span>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="navi-text">-->
        <!--                <div class="font-weight-bold">Mes Tâches</div>-->
        <!--                <div class="text-muted">Dernières tâches et projets</div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </router-link>-->
        <!--          &lt;!&ndash;end:Item&ndash;&gt;-->
        <!--        </div>-->
        <!--        &lt;!&ndash;end::Nav&ndash;&gt;-->
        <!--        <div class="separator separator-dashed my-7"></div>-->
        <!--begin::Notifications-->
        <!--        <div>-->
        <!--          &lt;!&ndash;begin:Heading&ndash;&gt;-->
        <!--          <h5 class="mb-5">Récentes Notifications</h5>-->
        <!--          &lt;!&ndash;end:Heading&ndash;&gt;-->
        <!--          <template v-for="(item, i) in list">-->
        <!--            &lt;!&ndash;begin::Item &ndash;&gt;-->
        <!--            <div-->
        <!--              class="d-flex align-items-center rounded p-5 gutter-b"-->
        <!--              v-bind:class="`bg-light-${item.type}`"-->
        <!--              v-bind:key="i"-->
        <!--            >-->
        <!--              <span class="svg-icon mr-5" :class="`svg-icon-${item.type}`">-->
        <!--                <span class="svg-icon svg-icon-lg">-->
        <!--                  &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
        <!--                  <inline-svg :src="item.svg" />-->
        <!--                  &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--                </span>-->
        <!--              </span>-->
        <!--              <div class="d-flex flex-column flex-grow-1 mr-2">-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"-->
        <!--                >-->
        <!--                  {{ item.title }}-->
        <!--                </a>-->
        <!--                <span class="text-muted font-size-sm">-->
        <!--                  {{ item.desc }}-->
        <!--                </span>-->
        <!--              </div>-->
        <!--              <span-->
        <!--                class="font-weight-bolder py-1 font-size-lg"-->
        <!--                :class="`text-${item.type}`"-->
        <!--              >-->
        <!--                {{ item.alt }}-->
        <!--              </span>-->
        <!--            </div>-->
        <!--            &lt;!&ndash;end::Item &ndash;&gt;-->
        <!--          </template>-->
        <!--        </div>-->
        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { mapGetters } from "vuex";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: []
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch("LOGOUT")
        .then(() => this.$router.push({ name: "Login" }))
        .then(() => {
          this.$bvToast.toast(`Vous êtes maintenant déconnecté.`, {
            title: "Déconnexion réussie.",
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    picture() {
      return process.env.BASE_URL + "media/svg/avatars/001-boy.svg";
    }
  }
};
</script>
