<template>
  <div>
    <ul
      class="menu-nav"
      v-if="
        user.roles[0].name === 'operateur' ||
          user.roles[0].name === 'admin' ||
          user.roles[0].name === 'superviseur'
      "
    >
      <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Tableau de bord</span>
          </a>
        </li>
      </router-link>
      <li class="menu-section">
        <h4 class="menu-text">Action Principale</h4>
        <i class="menu-icon flaticon-more-v2"></i>
      </li>
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="{ 'menu-item-open': hasActiveChildren('/ad') }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon fas fa-home"></i>
          <span class="menu-text">Gestion des Annonces</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Togohome</span>
              </span>
            </li>
            <router-link
              :to="{ name: 'ads.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Toutes les Annonces</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'rent.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Annonces locations</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'sell.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Annonces ventes</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'notyetvalidated.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-show="user.roles[0].name !== 'operateur'"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Annonce(s) en attente(s)</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'notvalidated.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Annonces non validé(e)s</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <router-link
        :to="{ name: 'partner.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des partenaires</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'product.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des Produits</span>
          </a>
        </li>
      </router-link>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="{ 'menu-item-open': hasActiveChildren('/service') }"
        v-show="user.roles[0].name !== 'operateur'"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon fas fa-home"></i>
          <span class="menu-text">Gestion des services</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Vue Bootstrap</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'service.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Liste des services</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'demande.service.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Liste des demandes de services</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="{ 'menu-item-open': hasActiveChildren('/diaspora') }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon fas fa-home"></i>
          <span class="menu-text">Gestion de la diaspora</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Vue Bootstrap</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'diaspora.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Annonces de la diaspora</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'offer.diaspora' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Demande de la diaspora</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="{ 'menu-item-open': hasActiveChildren('/user') }"
        v-show="
          user.roles[0].name !== 'operateur' && user.roles[0].name !== 'admin'
        "
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon fas fa-home"></i>
          <span class="menu-text">Gestion des Utilisateurs</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Vue Bootstrap</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'user.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Tous les Utilisateurs</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'internal.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Tous les utilisateurs internes</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'external.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Tous les utilisateurs externes</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'estate.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Liste des agents immobiliers</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <router-link
        :to="{ name: 'pub.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-show="
          user.roles[0].name !== 'operateur' && user.roles[0].name !== 'admin'
        "
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des publicités</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'district.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-show="
          user.roles[0].name !== 'operateur' && user.roles[0].name !== 'admin'
        "
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des quartiers</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'town.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-show="
          user.roles[0].name !== 'operateur' && user.roles[0].name !== 'admin'
        "
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des villes</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'category.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-show="
          user.roles[0].name !== 'operateur' && user.roles[0].name !== 'admin'
        "
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des catégories</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'tags.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-show="
          user.roles[0].name !== 'operateur' && user.roles[0].name !== 'admin'
        "
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des Tags</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'visit' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des visites</span>
          </a>
        </li>
      </router-link>
    </ul>
    <ul class="menu-nav" v-if="user.roles[0].name === 'admin'">
      <li class="menu-section">
        <h4 class="menu-text">Configuration</h4>
        <i class="menu-icon fas fa-home"></i>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="{ 'menu-item-open': hasActiveChildren('/user') }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon fas fa-home"></i>
          <span class="menu-text">Gestion des articles</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Vue Bootstrap</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'post.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Liste des articles</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'post.waiting.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Articles non publi(é)s</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="{ 'menu-item-open': hasActiveChildren('/user') }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon fas fa-home"></i>
          <span class="menu-text">Gestion des Utilisateurs</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Vue Bootstrap</span>
              </span>
            </li>

            <router-link
              :to="{ name: 'user.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Tous les Utilisateurs</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'internal.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Tous les utilisateurs internes</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'external.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Tous les utilisateurs externes</span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="{ name: 'estate.list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Liste des agents immobiliers</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <router-link
        :to="{ name: 'pub.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des publicités</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'district.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des quartiers</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'town.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des villes</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'message.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des messages</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'category.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des catégories</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'tags.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des Tags</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'role.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des roles</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'permission.list' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des permissions</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'role.list.permission' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Gestion des ACL</span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="{ name: 'partner.delete' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon fas fa-home"></i>
            <span class="menu-text">Historiques</span>
          </a>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  }
};
</script>
